@import "../../theme/colors.scss";

.ant-layout {
  overflow: hidden;
}
.ant-layout-content {
  overflow: scroll;
}
.ant-menu-title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;
  flex: none !important;
}

.ant-menu-item a {
  color: #fff !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item a {
  padding-left: 0;
}

.sider .anticon-left {
  position: relative !important;
  padding-left: 10px;
}

.sider .anticon {
  left: 0;
  position: sticky;
}

.ant-layout-sider {
  overflow-y: scroll;
}

.ant-drawer .ant-drawer-body {
  padding: 0;
}

.logout-menu-item {
  background: $light-color !important;
  color: $primary-color !important;
}

.logout-menu-item:hover {
  background: $primary-color !important;
  color: $light-color !important;
}
