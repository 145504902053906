.label-as::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.machinemap-form .ant-form-item {
  width: 30% !important;
}
