.chart-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.chart-row > * {
  flex: 1 1 calc(50% - 10px);
  margin-right: 10px; /* Add right margin to each item */
  margin-bottom: 20px;
}

/* Clear right margin for every second item to maintain alignment */
.chart-row > *:nth-child(even) {
  margin-right: 0;
}

/* Adjust for smaller screens */
@media (max-width: 560px) {
  .chart-row > * {
    flex: 1 1 100%; /* Each child takes full width on smaller screens */
    margin-right: 0; /* Remove right margin on smaller screens */
  }
}
