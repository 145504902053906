@import "./theme/colors.scss";

body {
  padding: 0;
  margin: 0;
}

/* Style the scrollbar track (the part the thumb slides in) */
::-webkit-scrollbar-track {
  background: $scrollbar-track;
  border-radius: 10px;
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb;
  border-radius: 10px;
}

/* Handle the scrollbar thumb hover state */
::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-thumb-hover;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 9px !important;
}
/* Style the scrollbar in Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb $scrollbar-track;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  background: $primary-color;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn .ant-typography {
  color: $light-color;
}

.ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ant-menu-item-icon {
  height: 34px;
  width: 25px;
}

.ant-layout .ant-layout-sider {
  overflow-y: auto !important;
}

.ant-table-body {
  overflow: auto !important;
  height: 50vh !important;
  min-height: 50vh !important;
  max-height: 50vh !important;
}

.ant-menu-item .ant-menu-item-selected {
  background-color: $primary-color !important;
  color: #fff;
}

/* Style the scrollbar track (the part the thumb slides in) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle the scrollbar thumb hover state */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
}

.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title {
  border: 1px solid #22ab53;
}

.ant-table-wrapper .ant-table-cell-fix-right-first::after {
  width: 100px !important;
}

.ant-table-wrapper .ant-table-cell-fix-right {
  right: -9px !important;
}