.card-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.card-flex-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  padding-right: 0;
}
.card-flex-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1em;
  margin-right: 0;
  padding: 0rem 1em;
}
.card-flex-image img {
  opacity: 1;
}
.card-flex-button {
  background-color: white;
  border: 1px solid #333;
  padding: 0.5rem;
  color: #333;
  text-align: center;
  text-decoration: none;
}
.card-flex-button:hover {
  color: #333;
  text-decoration: none;
}
.btn-block {
  display: block;
  width: 100%;
}

/* Custom page CSS - iPad/Desktop  */

@media (min-width: 768px) {
  .container .text-muted {
    text-align: end;
  }
  .card-flex-item {
    width: 20%;
    padding-right: 1em;
  }
  .card-flex-image img {
    opacity: 0.8;
  }
  .card-flex-wrapper:hover {
    cursor: pointer;
    /* background-color: #def5b8; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px 0px;
  }
  .card-flex-wrapper:hover .card-flex-image img {
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  }
  .card-flex-wrapper:hover .card-flex-button {
    background-color: #ffffcc;
  }
}

.card-header-text {
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  font-weight: 700;
}
